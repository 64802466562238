/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../css/dadosPessoais.css";
import {
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  withStyles,
  createTheme,
} from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";
import { lightBlue } from "@material-ui/core/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { pt } from "date-fns/locale";

import Termos from "../css/images/icons/termos-001.png";

//Components
import PreferenciaContacto from "./components/dadosPessoais/preferenciaContacto";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#05b6b7 !important",
        color: "white !important",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: "white !important",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white !important",
      },
    },
    MuiPickersDay: {
      day: {
        // color: lightBlue.A700,
        // color: '#05b6b7',
      },
      daySelected: {
        // backgroundColor: lightBlue['400'],
        backgroundColor: "#05b6b7",
        color: "white !important",
        "&:hover": {
          background: "#089091 !important",
        },
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        // color: lightBlue['900'],
        color: "#05b6b7",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue['400'],
        color: "#05b6b7",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#05b6b7",
      },
    },
  },
});

const CheckboxColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$checked": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function getSteps() {
  return ["Dados Pessoais"];
}
const BasicForm = ({
  dados,
  setDados,
  errorsDados = null,
  validationDados,
  validateOnChange = true,
}) => {
  const handleChange = (e) => {
    e.preventDefault();

    setDados({
      ...dados,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationDados({ [e.target.name]: e.target.value });
  };
  const handleNumbers = (e) => {
    e.preventDefault();

    let value = e.target.value.toString("0000-0000-0000");
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setDados({ ...dados, [e.target.name]: value });
    }

    if (e.target.name === "certidao_permanente") {
      value = value.replace(/(\d{4})(?=\d)/g, "$1-");
      setDados({ ...dados, certidao_permanente: value });
    }

    if (e.target.name === "numero_documento") {
      setDados({ ...dados, numero_documento: value });
    }

    if (validateOnChange) validationDados({ [e.target.name]: value });
  };

  const handleChangeTermos = (e) => {
    setDados({
      ...dados,
      aceito_condicoes_gerais: !dados.aceito_condicoes_gerais,
    });

    if (validateOnChange)
      validationDados({ [e.target.name]: !dados.aceito_condicoes_gerais });
  };
  const handleChangeTarifaSocial = (e) => {
    setDados({
      ...dados,
      tarifa_social: !dados.tarifa_social,
    });
  };
  const handleChangeTitularContratoAnterior = (e) => {
    setDados({
      ...dados,
      titular_contrato_anterior: !dados.titular_contrato_anterior,
    });
  };

  const handleChangeValidade = (date) => {
    setDados({
      ...dados,
      validade_documento: date,
    });
    if (validateOnChange) validationDados({ validade_documento: date });
  };

  return (
    <>
      <div className="titulo">Preencha as suas informações</div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div>
          <TextField
            id="Nome"
            name="nome"
            label="Nome *"
            value={dados.nome}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            className="nome"
            {...(errorsDados.nome && {
              error: true,
              helperText: errorsDados.nome,
            })}
          />
          <TextField
            id="Apelido"
            name="apelido"
            label="Apelido *"
            value={dados.apelido}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            className="apelido"
            {...(errorsDados.apelido && {
              error: true,
              helperText: errorsDados.apelido,
            })}
          />
        </div>
        <div>
          <TextField
            id="telemovel"
            name="telemovel"
            label="Telemóvel *"
            inputProps={{ maxLength: 9 }}
            value={dados.telemovel}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            className="textfield"
            style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
            {...(errorsDados.telemovel && {
              error: true,
              helperText: errorsDados.telemovel,
            })}
          />

          <TextField
            id="Nif"
            name="nif"
            label="NIF *"
            inputProps={{ maxLength: 9 }}
            value={dados.nif}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8, width: "48%" }}
            {...(errorsDados.nif && {
              error: true,
              helperText: errorsDados.nif,
            })}
          />
        </div>
        <div>
          <TextField
            id="numero_documento"
            name="numero_documento"
            label="Nº de cartão de cidadão/BI *"
            inputProps={{ maxLength: 15 }}
            value={dados.numero_documento}
            onChange={handleNumbers}
            fullWidth
            autoComplete="off"
            className="numero_documento"
            style={{ marginBottom: 8, width: "48%", marginRight: "4%" }}
            {...(errorsDados.numero_documento && {
              error: true,
              helperText: errorsDados.numero_documento,
            })}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            {/* <Grid container justify='center'> */}
            <KeyboardDatePicker
              style={{ width: "48%", margin: "0px" }}
              className="validade_documento"
              margin="normal"
              id="date-picker-dialog"
              label="Validade do Documento *"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dados?.validade_documento}
              minDate={new Date()}
              minDateMessage={"A Data não pode menor que o valor mínimo (Hoje)"}
              maxDateMessage={
                "A Data escolhida excede a data máxima do calendário"
              }
              onChange={handleChangeValidade}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              {...(errorsDados.validade_documento && {
                error: true,
                helperText: errorsDados.validade_documento,
              })}
            />
            {/* </Grid> */}
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <TextField
            id="Email"
            name="email"
            label="Email *"
            value={dados.email}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8 }}
            {...(errorsDados.email && {
              error: true,
              helperText: errorsDados.email,
            })}
          />
        </div>

        {dados.nif && dados.nif.split("")[0] == 5 && (
          <>
            <div>
              <TextField
                id="certidao_permanente"
                name="certidao_permanente"
                label="Certidão permanente *"
                value={dados.certidao_permanente}
                inputProps={{ maxLength: 14 }}
                onChange={handleNumbers}
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 8 }}
                {...(errorsDados.certidao_permanente && {
                  error: true,
                  helperText: errorsDados.certidao_permanente,
                })}
              />
            </div>
          </>
        )}
        {/* Tarifa Social */}
        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.tarifa_social}
                  onChange={handleChangeTarifaSocial}
                  name="tarifa_social"
                />
              }
              label="Beneficio de Tarifa Social"
            />
          </div>
        </FormGroup>
        {/* Titular de contrato anterior */}
        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.titular_contrato_anterior}
                  onChange={handleChangeTitularContratoAnterior}
                  name="titular_contrato_anterior"
                />
              }
              label="O titular é o mesmo do contrato anterior (de outro comercializador)"
            />
          </div>
        </FormGroup>

        {/* Preferência de Contacto */}

        <PreferenciaContacto
          dados={dados}
          setDados={setDados}
          errorsDados={errorsDados}
          validationDados={validationDados}
        />

        {/* Condições Gerais */}
        <FormGroup
          row
          {...(errorsDados.aceito_condicoes_gerais && {
            error: "true",
          })}
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.aceito_condicoes_gerais}
                  onChange={handleChangeTermos}
                  name="aceito_condicoes_gerais"
                />
              }
              label="Aceito os termos e as condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
            />
            <a
              href="https://jafplus.pt/politica-de-privacidade-e-protecao-de-dados-pessoais/"
              target="blank"
            >
              <img
                src={Termos}
                alt=""
                style={{
                  height: "15px",
                  borderRadius: "10px",
                  verticalAlign: "middle",
                }}
              />
            </a>
          </div>

          <div>
            {errorsDados.aceito_condicoes_gerais && (
              <FormHelperText style={{ color: "#f44336" }}>
                {errorsDados.aceito_condicoes_gerais}
              </FormHelperText>
            )}
          </div>
        </FormGroup>
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  isDisabledSeguinte,
  setIsDisabledSeguinte,
  dados,
  setDados,
  errorsDados,
  resetFormDados,
  validationDados,
  validateOnChange,
  validationDados1
) {
  switch (step) {
    case 0:
      return (
        <BasicForm
          isDisabledSeguinte={isDisabledSeguinte}
          setIsDisabledSeguinte={setIsDisabledSeguinte}
          dados={dados}
          setDados={setDados}
          errorsDados={errorsDados}
          resetFormDados={resetFormDados}
          validationDados={validationDados}
          validateOnChange={validateOnChange}
          validationDados1={validationDados1}
        />
      );
    default:
      return "unknown step";
  }
}

const DadosPessoais = ({
  methods,
  setValue,
  dados,
  setDados,
  resetFormDados,
  errorsDados,
  validationDados,
  errorsDados1,
  validationDados1,
  validateOnChange = true,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [isDisabledSeguinte, setIsDisabledSeguinte] = useState(true);
  const steps = getSteps();
  const handleNext = (data) => {
    if (validationDados()) {
      if (activeStep === 1 && validationDados1()) {
        setActiveStep(activeStep + 1);
      }
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      }
    } else {
      return null;
    }

    // }
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <br />
            <br />
            {getStepContent(
              activeStep,
              isDisabledSeguinte,
              setIsDisabledSeguinte,
              dados,
              setDados,
              errorsDados,
              resetFormDados,
              validationDados,
              validateOnChange,
              errorsDados1,
              validationDados1
            )}

            <br />

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default DadosPessoais;
