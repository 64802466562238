import React, { useState } from "react";
import "../css/energia.css";
import Simples from "./potencias/Eletricidade/simples";
import BiHorario from "./potencias/Eletricidade/bi_horario";
import TriHorario from "./potencias/Eletricidade/tri_horario";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Button, createMuiTheme } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import MaisEnergia from "../css/images/tipoEnergia/mais-energia-pink-001.png";
import SimplesIcon from "../css/images/icons/icone-tarifa-simples-001.png";
import BiHorarioIcon from "../css/images/icons/icone-tarifa-bi-horario-001.png";
import TriHorarioIcon from "../css/images/icons/icone-tarifa-tri-horario-001.png";

// Ciclo
import CicloSimples from "./ciclos/MaisEnergia/simples";
import CicloBiHorario from "./ciclos/MaisEnergia/bi-horario";
import CicloTriHorario from "./ciclos/MaisEnergia/tri-horario";

import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  btnNormal: {
    display: "inline-block",
    width: 130,
    height: 130,
    borderRadius: "10px",
    margin: "10px",
  },
  btnActive: {
    display: "inline-block",
    width: 130,
    height: 130,
    boxShadow: "0px 4px 8px 10px #ebebeb",
    borderRadius: "10px",
    margin: "10px",
  },
  btnNormalSmall: {
    display: "inline-block",
    width: 130,
    height: 130,
    borderRadius: "10px",
    margin: "10px",
  },
  btnActiveSmall: {
    display: "inline-block",
    width: 130,
    height: 130,
    boxShadow: "0px 4px 8px 10px #ebebeb",
    borderRadius: "10px",
    margin: "10px",
  },
  btnNormalTiny: {
    display: "inline-block",
    width: 130,
    height: 60,
    borderRadius: "10px",
    margin: "10px",
  },
  btnActiveTiny: {
    display: "inline-block",
    width: 130,
    height: 60,
    boxShadow: "0px 4px 8px 10px #ebebeb",
    borderRadius: "10px",
    margin: "10px",
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
});

function getSteps() {
  return ["Energias"];
}

const PaymentForm = ({
  energia,
  setEnergia,
  validateOnChange = true,
  //errors
  validEnergias,
  setValidEnergias,
  errorsEnergia,
  setErrorsEnergia,
  validationEnergia,
  //errors 1 - potencia e tarifa
  setValidEnergias1,
  validEnergias1,
  setErrorsEnergia1,
  validationEnergia1,
  errorsEnergia1 = null,
  //errors 2 - escalão
  setValidEnergias2,
  validEnergias2,
  setErrorsEnergia2,
  validationEnergia2,
  errorsEnergia2 = null,
  //errors 3 - ciclo
  setValidEnergias3,
  validEnergias3,
  setErrorsEnergia3,
  validationEnergia3,
  errorsEnergia3 = null,
}) => {
  const classes = useStyles();
  const handleChange = (e, value) => {
    console.log(value);
    if (value === "eletricidade") {
      setEnergia({
        ...energia,
        tipo1: value,
        is_electricidade_gas: false,
        isElectricidade: true,
        isGas: false,
        isMaisEnergia: false,
        // eletricidade
        tarifa: "",
        // gas
        escalao: "",
        potencia: "",
        //Ciclo
        ciclo: "",
      });
      setErrorsEnergia({});
      setErrorsEnergia1({});
      setErrorsEnergia2({});
      setErrorsEnergia3({});

      setValidEnergias();
      setValidEnergias1();
      setValidEnergias2();
      setValidEnergias3();
    } else if (value === "eletricidade_e_gas") {
      setEnergia({
        ...energia,
        tipo1: value,
        is_electricidade_gas: true,
        isElectricidade: false,
        isGas: false,
        isMaisEnergia: false,
        // eletricidade
        tarifa: "",
        // gas
        escalao: "",
        potencia: "",
        //Ciclo
        ciclo: "",
      });
      setErrorsEnergia({});
      setErrorsEnergia1({});
      setErrorsEnergia2({});
      setErrorsEnergia3({});

      setValidEnergias();
      setValidEnergias1();
      setValidEnergias2();
      setValidEnergias3();
    } else if (value === "gas") {
      setEnergia({
        ...energia,
        tipo1: value,
        is_electricidade_gas: false,
        isElectricidade: false,
        isGas: true,
        isMaisEnergia: false,
        // eletricidade
        tarifa: "",
        potencia: "",
        // gas
        escalao: "",
        //Ciclo
        ciclo: "",
      });
      setErrorsEnergia({});
      setErrorsEnergia1({});
      setErrorsEnergia2({});
      setErrorsEnergia3({});

      setValidEnergias();
      setValidEnergias1();
      setValidEnergias2();
      setValidEnergias3();
    } else if (value === "maisEnergia") {
      setEnergia({
        ...energia,
        tipo1: value,
        is_electricidade_gas: false,
        isElectricidade: false,
        isGas: false,
        isMaisEnergia: true,
        // eletricidade
        tarifa: "",
        // gas
        escalao: "",
        potencia: "",
        //Ciclo
        ciclo: "",
      });
      setErrorsEnergia({});
      setErrorsEnergia1({});
      setErrorsEnergia2({});
      setErrorsEnergia3({});

      setValidEnergias();
      setValidEnergias1();
      setValidEnergias2();
      setValidEnergias3();
    }
    setErrorsEnergia({});
    setErrorsEnergia1({});
    setErrorsEnergia2({});
    setErrorsEnergia3({});

    setValidEnergias();
    setValidEnergias1();
    setValidEnergias2();
    setValidEnergias3();
    if (validateOnChange) validationEnergia({ tipo1: value });
  };

  useEffect(() => validEnergias1);
  // useEffect(() => validEnergias2);

  const handleButton = (e, value) => {
    setEnergia({
      ...energia,
      tarifa: value,
      potencia: "",
      ciclo: "",
    });

    if (validateOnChange) validationEnergia1({ tarifa: value });
  };
  const handleButtonEscalao = (e, value) => {
    setEnergia({
      ...energia,
      escalao: value,
    });

    if (validateOnChange) validationEnergia2({ escalao: value });
  };

  const handleButtonCiclo = (e, value) => {
    setEnergia({
      ...energia,
      ciclo: value,
    });

    if (validateOnChange) validationEnergia3({ ciclo: value });
  };

  return (
    <>
      <div className="titulo">Escolha a sua opção energética</div>
      <div className="step0energia">
        {/* <Button
          name='tipo1'
          onClick={(e, value) =>
            handleChange(e, (value = 'eletricidade_e_gas'))
          }
          className={
            energia.tipo1 === 'eletricidade_e_gas'
              ? classes.btnActive
              : classes.btnNormal
          }
        >
          <div>
            <img src={EletricidadeEGas} alt='' style={{ width: 50 }} />
          </div>
          <div>eletricidade e gás</div>
        </Button> */}
        {/* <Button
          name='tipo1'
          onClick={(e, value) => handleChange(e, (value = 'eletricidade'))}
          className={
            energia.tipo1 === 'eletricidade'
              ? classes.btnActive
              : classes.btnNormal
          }
        >
          <div>
            <img src={Eletricidade} alt='' style={{ width: 50 }} />
          </div>
          <div>eletricidade</div>
        </Button> */}
        <Button
          name="tipo1"
          onClick={(e, value) => handleChange(e, (value = "maisEnergia"))}
          className={
            energia.tipo1 === "maisEnergia"
              ? classes.btnActive
              : classes.btnNormal
          }
        >
          <div>
            <img src={MaisEnergia} alt="" style={{ width: 35 }} />
          </div>
          <div>mais energia</div>
        </Button>
        {/* <Button
          name='tipo1'
          onClick={(e, value) => handleChange(e, (value = 'gas'))}
          className={
            energia.tipo1 === 'gas' ? classes.btnActive : classes.btnNormal
          }
        >
          <div>
            <img src={Gas} alt='' style={{ width: 50 }} />
          </div>
          <div>gás</div>
        </Button> */}
      </div>

      <br />
      {validEnergias === false && (
        <>
          <span style={{ color: "red", fontSize: "10px" }}>
            Por favor, indique uma energia.
          </span>
          <br />
        </>
      )}

      {/* ELETRICIDADE */}
      {energia.tipo1 === "eletricidade" ? (
        <>
          <div className="titulo">Escolha a tarifa que pretende</div>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className="step1energia">
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "simples"))}
                className={
                  energia.tarifa === "simples"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={SimplesIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Simples</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "bi_horario"))}
                className={
                  energia.tarifa === "bi_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={BiHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Bi-Horário</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "tri_horario"))}
                className={
                  energia.tarifa === "tri_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Tri-Horário</div>
              </Button>
            </div>

            <br />
            <div>
              {energia.tarifa === "simples" && (
                <Simples
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "bi_horario" && (
                <BiHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "tri_horario" && (
                <TriHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <br />

            {validEnergias1 === false && (
              <>
                <span style={{ color: "red", fontSize: "10px" }}>
                  Por favor, indique uma tarifa.
                </span>
                <br />
                <br />
              </>
            )}
          </ThemeProvider>
        </>
      ) : null}
      {/* ELETRICIDADE E GAS */}
      {energia.tipo1 === "eletricidade_e_gas" ? (
        <>
          <div className="titulo">Escolha a tarifa que pretende</div>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className="step1energia">
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "simples"))}
                className={
                  energia.tarifa === "simples"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={SimplesIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Simples</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "bi_horario"))}
                className={
                  energia.tarifa === "bi_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={BiHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Bi-Horário</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "tri_horario"))}
                className={
                  energia.tarifa === "tri_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Tri-Horário</div>
              </Button>
            </div>

            <br />
            <div>
              {energia.tarifa === "simples" && (
                <Simples
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "bi_horario" && (
                <BiHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "tri_horario" && (
                <TriHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <br />
            {/* eslint-disable-next-line eqeqeq */}
            {validEnergias1 == false && (
              <>
                <span style={{ color: "red", fontSize: "10px" }}>
                  Por favor, indique uma tarifa.
                </span>
                <br />
                <br />
              </>
            )}
            <>
              <div className="titulo">Escolha o seu escalão</div>

              <div className="step1energia">
                <Button
                  name="escalao"
                  onClick={(e, value) =>
                    handleButtonEscalao(e, (value = "escalao1"))
                  }
                  className={
                    energia.escalao === "escalao1"
                      ? classes.btnActiveSmall
                      : classes.btnNormalSmall
                  }
                >
                  <div>
                    <img src={SimplesIcon} alt="" style={{ width: 35 }} />
                    <br />
                    <br />
                  </div>
                  <div>Escalão 1</div>
                </Button>
                <Button
                  name="escalao"
                  onClick={(e, value) =>
                    handleButtonEscalao(e, (value = "escalao2"))
                  }
                  className={
                    energia.escalao === "escalao2"
                      ? classes.btnActiveSmall
                      : classes.btnNormalSmall
                  }
                >
                  <div>
                    <img src={BiHorarioIcon} alt="" style={{ width: 35 }} />
                    <br />
                    <br />
                  </div>
                  <div>Escalão 2</div>
                </Button>
                <Button
                  name="escalao"
                  onClick={(e, value) =>
                    handleButtonEscalao(e, (value = "escalao3"))
                  }
                  className={
                    energia.escalao === "escalao3"
                      ? classes.btnActiveSmall
                      : classes.btnNormalSmall
                  }
                >
                  <div>
                    <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                    <br />
                    <br />
                  </div>
                  <div>Escalão 3</div>
                </Button>
                <Button
                  name="escalao"
                  onClick={(e, value) =>
                    handleButtonEscalao(e, (value = "escalao4"))
                  }
                  className={
                    energia.escalao === "escalao4"
                      ? classes.btnActiveSmall
                      : classes.btnNormalSmall
                  }
                >
                  <div>
                    <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                    <br />
                    <br />
                  </div>
                  <div>Escalão 4</div>
                </Button>
              </div>
              <br />

              {/* eslint-disable-next-line eqeqeq */}
              {validEnergias2 == false && (
                <>
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Por favor, indique um escalão.
                  </span>
                  <br />
                  <br />
                </>
              )}
            </>
          </ThemeProvider>
        </>
      ) : null}

      {/* GAS */}
      {energia.tipo1 === "gas" ? (
        <>
          <div className="titulo">Escolha o seu escalão</div>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className="step1energia">
              <Button
                name="escalao"
                onClick={(e, value) =>
                  handleButtonEscalao(e, (value = "escalao1"))
                }
                className={
                  energia.escalao === "escalao1"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={SimplesIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Escalão 1</div>
              </Button>
              <Button
                name="escalao"
                onClick={(e, value) =>
                  handleButtonEscalao(e, (value = "escalao2"))
                }
                className={
                  energia.escalao === "escalao2"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={BiHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Escalão 2</div>
              </Button>
              <Button
                name="escalao"
                onClick={(e, value) =>
                  handleButtonEscalao(e, (value = "escalao3"))
                }
                className={
                  energia.escalao === "escalao3"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Escalão 3</div>
              </Button>
              <Button
                name="escalao"
                onClick={(e, value) =>
                  handleButtonEscalao(e, (value = "escalao4"))
                }
                className={
                  energia.escalao === "escalao4"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Escalão 4</div>
              </Button>
            </div>
            <br />

            {/* eslint-disable-next-line eqeqeq */}
            {validEnergias2 == false && (
              <>
                <span style={{ color: "red", fontSize: "10px" }}>
                  Por favor, indique um escalão.
                </span>
                <br />
                <br />
              </>
            )}
          </ThemeProvider>
        </>
      ) : null}
      {/* MAIS ENERGIA */}
      {energia.tipo1 === "maisEnergia" ? (
        <>
          <div className="titulo">Escolha a tarifa que pretende</div>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className="step1energia">
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "simples"))}
                className={
                  energia.tarifa === "simples"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={SimplesIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Simples</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "bi_horario"))}
                className={
                  energia.tarifa === "bi_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={BiHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Bi-Horário</div>
              </Button>
              <Button
                name="tarifa"
                onClick={(e, value) => handleButton(e, (value = "tri_horario"))}
                className={
                  energia.tarifa === "tri_horario"
                    ? classes.btnActiveSmall
                    : classes.btnNormalSmall
                }
              >
                <div>
                  <img src={TriHorarioIcon} alt="" style={{ width: 35 }} />
                  <br />
                  <br />
                </div>
                <div>Tri-Horário</div>
              </Button>
            </div>

            <br />
            <div>
              {energia.tarifa === "simples" && (
                <Simples
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "bi_horario" && (
                <BiHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <div>
              {energia.tarifa === "tri_horario" && (
                <TriHorario
                  energia={energia}
                  setEnergia={setEnergia}
                  errorsEnergia1={errorsEnergia1}
                  validateOnChange={validateOnChange}
                  validationEnergia1={validationEnergia1}
                />
              )}
            </div>
            <br />

            {validEnergias1 === false && (
              <>
                <span style={{ color: "red", fontSize: "10px" }}>
                  Por favor, indique uma tarifa.
                </span>
                <br />
                <br />
              </>
            )}
          </ThemeProvider>
        </>
      ) : null}

      {/* ------ Seleção do ciclo -------*/}

      {console.log(energia.tarifa === "simples")}

      <>
        <ThemeProvider theme={defaultMaterialTheme}>
          {energia?.potencia?.length > 0 ? (
            <>
              <div className="titulo">Escolha o seu Ciclo</div>
              <div className="step3energia">
                {/* <Button
                  name='semCiclo'
                  onClick={(e, value) =>
                    handleButtonCiclo(e, (value = 'semCiclo'))
                  }
                  className={
                    energia.ciclo === 'semCiclo'
                      ? classes.btnActiveTiny
                      : classes.btnNormalTiny
                  }
                >
                  <div>S/Ciclo</div>
                </Button>
                <Button
                  name='diario'
                  onClick={(e, value) =>
                    handleButtonCiclo(e, (value = 'diario'))
                  }
                  className={
                    energia.ciclo === 'diario'
                      ? classes.btnActiveTiny
                      : classes.btnNormalTiny
                  }
                >
                  <div>Diário</div>
                </Button>
                <Button
                  name='semanal'
                  onClick={(e, value) =>
                    handleButtonCiclo(e, (value = 'semanal'))
                  }
                  className={
                    energia.ciclo === 'semanal'
                      ? classes.btnActiveTiny
                      : classes.btnNormalTiny
                  }
                >
                  <div>Semanal</div>
                </Button>
                <Button
                  name='semanalOpcional'
                  onClick={(e, value) =>
                    handleButtonCiclo(e, (value = 'semanalOpcional'))
                  }
                  className={
                    energia.ciclo === 'semanalOpcional'
                      ? classes.btnActiveTiny
                      : classes.btnNormalTiny
                  }
                >
                  <div>Semanal Opcional</div>
                </Button> */}

                {energia?.tarifa === "simples" ? (
                  <CicloSimples
                    energia={energia}
                    classes={classes}
                    handleButtonCiclo={handleButtonCiclo}
                  />
                ) : null}
                {energia?.tarifa === "bi_horario" ? (
                  <CicloBiHorario
                    energia={energia}
                    classes={classes}
                    handleButtonCiclo={handleButtonCiclo}
                  />
                ) : null}
                {energia?.tarifa === "tri_horario" ? (
                  <CicloTriHorario
                    energia={energia}
                    classes={classes}
                    handleButtonCiclo={handleButtonCiclo}
                  />
                ) : null}
              </div>

              {validEnergias3 === false && (
                <>
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Por favor, indique um ciclo.
                  </span>
                  <br />
                  <br />
                </>
              )}
            </>
          ) : null}

          <br />
          <br />
        </ThemeProvider>
      </>
    </>
  );
};

function getStepContent(
  step,
  methods,
  energia,
  setEnergia,
  validateOnChange,
  //errors
  validEnergias,
  setValidEnergias,
  errorsEnergia,
  setErrorsEnergia,
  validationEnergia,
  //errors 1 - potencia e tarifa
  validEnergias1,
  setValidEnergias1,
  errorsEnergia1,
  setErrorsEnergia1,
  validationEnergia1,
  //errors 2 - escalao
  validEnergias2,
  setValidEnergias2,
  errorsEnergia2,
  setErrorsEnergia2,
  validationEnergia2,
  //errors 2 - ciclo
  validEnergias3,
  setValidEnergias3,
  errorsEnergia3,
  setErrorsEnergia3,
  validationEnergia3
) {
  switch (step) {
    case 0:
      return (
        <PaymentForm
          methods={methods}
          energia={energia}
          setEnergia={setEnergia}
          validationEnergia={validationEnergia}
          errorsEnergia={errorsEnergia}
          setValidEnergias={setValidEnergias}
          validateOnChange={validateOnChange}
          validEnergias={validEnergias}
          setErrorsEnergia={setErrorsEnergia}
          //errors 1 - potencia e tarifa
          validationEnergia1={validationEnergia1}
          setErrorsEnergia1={setErrorsEnergia1}
          validEnergias1={validEnergias1}
          setValidEnergias1={setValidEnergias1}
          errorsEnergia1={errorsEnergia1}
          //errors 2 - escalao
          validationEnergia2={validationEnergia2}
          setErrorsEnergia2={setErrorsEnergia2}
          validEnergias2={validEnergias2}
          setValidEnergias2={setValidEnergias2}
          errorsEnergia2={errorsEnergia2}
          //errors 2 - escalao
          validationEnergia3={validationEnergia3}
          setErrorsEnergia3={setErrorsEnergia3}
          validEnergias3={validEnergias3}
          setValidEnergias3={setValidEnergias3}
          errorsEnergia3={errorsEnergia3}
        />
      );

    default:
      return "unknown step";
  }
}

const Energias = ({
  value,
  methods,
  setValue,
  energia,
  setEnergia,
  validateOnChange = true,
  // errors
  validationEnergia,
  errorsEnergia,
  setErrorsEnergia,
  validEnergias,
  setValidEnergias,
  //errors 1 - potencia e tarifa
  validEnergias1,
  setValidEnergias1,
  errorsEnergia1,
  setErrorsEnergia1,
  validationEnergia1,
  //errors 2 - escalao
  validEnergias2,
  setValidEnergias2,
  errorsEnergia2,
  setErrorsEnergia2,
  validationEnergia2,
  //errors 3 - ciclo
  validEnergias3,
  setValidEnergias3,
  errorsEnergia3,
  setErrorsEnergia3,
  validationEnergia3,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (energia.tipo1 === "eletricidade" && validationEnergia()) {
      if (validationEnergia1() && validationEnergia3()) {
        setActiveStep(activeStep + 1);
        // eslint-disable-next-line eqeqeq
      } else if (energia.tarifa.length == 0) {
        setValidEnergias1(false);
      }
    } else if (energia.tipo1 === "eletricidade_e_gas" && validationEnergia()) {
      if (
        validationEnergia1() &&
        validationEnergia2() &&
        validationEnergia3()
      ) {
        setActiveStep(activeStep + 1);
      }
      // eslint-disable-next-line eqeqeq
      if (Object.keys(energia.tarifa).length == 0) {
        setValidEnergias1(false);
      }
      // eslint-disable-next-line eqeqeq
      if (Object.keys(energia.escalao).length == 0) {
        setValidEnergias2(false);
      }
      if (Object.keys(energia.ciclo).length === 0) {
        setValidEnergias3(false);
      }
    } else if (energia.tipo1 === "gas" && validationEnergia()) {
      if (validationEnergia2() && validationEnergia3()) {
        setActiveStep(activeStep + 1);
        // eslint-disable-next-line eqeqeq
      } else if (energia.tarifa.length == 0) {
        setValidEnergias2(false);
      }
    } else if (energia.tipo1 === "maisEnergia" && validationEnergia()) {
      if (validationEnergia1() && validationEnergia3()) {
        setActiveStep(activeStep + 1);
        // eslint-disable-next-line eqeqeq
      } else if (energia.tarifa.length == 0) {
        setValidEnergias1(false);
        // eslint-disable-next-line eqeqeq
      } else if (energia.ciclo.length == 0) {
        setValidEnergias3(false);
      }
    } else {
      setValidEnergias(false);
    }
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="energiaBody">
      {activeStep === steps.length ? (
        setValue(value + 1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            {" "}
            <Button
              className={classes.button}
              onClick={handleBack}
              style={{
                paddingRight: 10,
                borderRadius: "25px",
              }}
            >
              <ChevronLeft />
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                  marginTop: "1px",
                }}
              >
                voltar
              </span>
            </Button>
            <br />
            <br />
            {getStepContent(
              activeStep,
              methods,
              energia,
              setEnergia,
              validateOnChange,
              //errors
              validEnergias,
              setValidEnergias,
              errorsEnergia,
              setErrorsEnergia,
              validationEnergia,

              //errors 1 - potencia e tarifa
              validEnergias1,
              setValidEnergias1,
              errorsEnergia1,
              setErrorsEnergia1,
              validationEnergia1,
              //errors 2 - escalao
              validEnergias2,
              setValidEnergias2,
              errorsEnergia2,
              setErrorsEnergia2,
              validationEnergia2,
              //errors 3 - ciclo
              validEnergias3,
              setValidEnergias3,
              errorsEnergia3,
              setErrorsEnergia3,
              validationEnergia3
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                  // marginTop: '2px',
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default Energias;
