/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import '../css/localizacao.css';
import {
  TextField,
  Button,
  createMuiTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: '#05b6b7' },
  },
});
const CheckboxColored = withStyles({
  root: {
    color: '#05b6b7',
    '&$checked': {
      color: '#05b6b7',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

function getSteps() {
  return ['Localização'];
}

const PersonalForm = ({
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao = null,

  validateOnChange = true,
}) => {
  const [maxLength, setMaxLength] = useState(20);

  const cpeMaxLength = (value) => {
    setMaxLength(value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let texto = e.target.value;
    if (e.target.name === 'cod_postal') {
      texto = texto.replace(/\s+/g, '');
      texto = texto.replace(/(\d{4})(?=\d)/g, '$1-');
    }
    if (e.target.name === 'cpe') {
      if (texto.includes(' ')) {
        if (texto.split(' ').length == 1) {
          // console.log('Yes, it has 1 space');
          cpeMaxLength(20);
        } else if (texto.split(' ').length == 2) {
          // console.log('Yes, it has 2 space');
          cpeMaxLength(21);
        } else if (texto.split(' ').length == 3) {
          // console.log('Yes, it has 3 space');
          cpeMaxLength(22);
        } else if (texto.split(' ').length == 4) {
          // console.log('Yes, it has 4 space');
          cpeMaxLength(23);
        } else if (texto.split(' ').length == 5) {
          // console.log('Yes, it has 5 space');
          cpeMaxLength(24);
        } else if (texto.split(' ').length == 6) {
          // console.log('Yes, it has 6 space');
          cpeMaxLength(25);
        }
      } else {
        cpeMaxLength(20);
      }
    }

    setLocalidade({
      ...localidade,
      [e.target.name]: texto,
    });

    if (validateOnChange)
      validationLocalizacao({ [e.target.name]: e.target.value });
  };

  const handleChangeNovaLigacao = (e) => {
    setLocalidade({
      ...localidade,
      nova_ligacao: !localidade.nova_ligacao,
    });
  };

  const handleChangeFornecimentoEnergia = (e) => {
    setLocalidade({
      ...localidade,
      fornecimento_energia: !localidade.fornecimento_energia,
    });
  };

  return (
    <>
      <div className='titulo'>Qual o local de instalação?</div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div>
          <TextField
            id='Morada'
            name='morada'
            label='Morada *'
            className='morada'
            value={localidade.morada}
            onChange={handleChange}
            fullWidth
            autoComplete='off'
            style={{ marginBottom: 8 }}
            {...(errorsLocalizacao.morada && {
              error: true,
              helperText: errorsLocalizacao.morada,
            })}
          />
          <TextField
            id='cpe'
            name='cpe'
            label='CPE *'
            className='cpe'
            value={localidade.cpe}
            onChange={handleChange}
            fullWidth
            autoComplete='off'
            style={{ marginBottom: 8 }}
            inputProps={{ maxLength: maxLength }}
            {...(errorsLocalizacao.cpe && {
              error: true,
              helperText: errorsLocalizacao.cpe,
            })}
          />
        </div>
        <div>
          <TextField
            id='localidade'
            name='localidade'
            label='Localidade *'
            value={localidade.localidade}
            className='localidade'
            onChange={handleChange}
            fullWidth
            autoComplete='off'
            {...(errorsLocalizacao.localidade && {
              error: true,
              helperText: errorsLocalizacao.localidade,
            })}
          />
          <TextField
            id='cod_postal'
            name='cod_postal'
            label='Código Postal *'
            className='cod_postal'
            value={localidade.cod_postal}
            onChange={handleChange}
            fullWidth
            autoComplete='off'
            inputProps={{ maxLength: 8 }}
            {...(errorsLocalizacao.cod_postal && {
              error: true,
              helperText: errorsLocalizacao.cod_postal,
            })}
          />
        </div>
        {/* Nova Ligação */}
        <FormGroup
          row
          style={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            marginTop: '10px',
          }}
        >
          <div>
            {' '}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.nova_ligacao}
                  onChange={handleChangeNovaLigacao}
                  name='nova_ligacao'
                />
              }
              label='Esta é uma nova ligação'
            />
          </div>
        </FormGroup>
        {/* Fornecimento de energia */}
        <FormGroup
          row
          style={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
          }}
        >
          <div>
            {' '}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.fornecimento_energia}
                  onChange={handleChangeFornecimentoEnergia}
                  name='fornecimento_energia'
                />
              }
              label='Este local tem fornecimento de energia'
            />
          </div>
        </FormGroup>
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao
) {
  switch (step) {
    case 0:
      return (
        <PersonalForm
          localidade={localidade}
          setLocalidade={setLocalidade}
          validationLocalizacao={validationLocalizacao}
          errorsLocalizacao={errorsLocalizacao}
        />
      );

    default:
      return 'unknown step';
  }
}

const Localizacao = ({
  value,
  methods,
  setValue,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const handleNext = (data) => {
    if (validationLocalizacao()) {
      setActiveStep(activeStep + 1);
    } else return null;
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(value + 1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <Button
              className={classes.button}
              onClick={handleBack}
              style={{
                paddingRight: 10,
                borderRadius: '25px',
              }}
            >
              <ChevronLeft />
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: 'flex',
                  marginTop: '1px',
                }}
              >
                voltar
              </span>
            </Button>
            <br />
            <br />
            {getStepContent(
              activeStep,
              localidade,
              setLocalidade,
              validationLocalizacao,
              errorsLocalizacao
            )}
            <br />
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              type='submit'
              style={{
                paddingRight: 10,
                backgroundColor: '#05b6b7',
                borderRadius: '25px',
                color: 'white',
              }}
              size='large'
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: 'flex',
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default Localizacao;
