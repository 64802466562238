/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../css/localizacao.css";
import {
  TextField,
  Button,
  createMuiTheme,
  FormControlLabel,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
});

const RadioColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$selected": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function getSteps() {
  return ["Localização"];
}

const PersonalForm = ({
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao = null,
  validateOnChange = true,
}) => {
  const [maxLength, setMaxLength] = useState(20);

  const cuiMaxLength = (value) => {
    setMaxLength(value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let texto = e.target.value;
    if (e.target.name === "cod_postal") {
      texto = texto.replace(/\s+/g, "");
      texto = texto.replace(/(\d{4})(?=\d)/g, "$1-");
    }
    if (e.target.name === "cui") {
      if (texto.includes(" ")) {
        if (texto.split(" ").length == 1) {
          // console.log('Yes, it has 1 space');
          cuiMaxLength(20);
        } else if (texto.split(" ").length == 2) {
          // console.log('Yes, it has 2 space');
          cuiMaxLength(21);
        } else if (texto.split(" ").length == 3) {
          // console.log('Yes, it has 3 space');
          cuiMaxLength(22);
        } else if (texto.split(" ").length == 4) {
          // console.log('Yes, it has 4 space');
          cuiMaxLength(23);
        } else if (texto.split(" ").length == 5) {
          // console.log('Yes, it has 5 space');
          cuiMaxLength(24);
        } else if (texto.split(" ").length == 6) {
          // console.log('Yes, it has 6 space');
          cuiMaxLength(25);
        }
      } else {
        cuiMaxLength(20);
      }
    }

    setLocalidade({
      ...localidade,
      [e.target.name]: texto,
    });

    if (validateOnChange)
      validationLocalizacao({ [e.target.name]: e.target.value });
  };

  const handleChangeRadioFornecimento = (event) => {
    setLocalidade({
      ...localidade,
      fornecimento_gas: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  const handleChangeRadioInspecao = (event) => {
    setLocalidade({
      ...localidade,
      inspecao_gas: event.target.value,
    });
    if (validateOnChange)
      validationLocalizacao({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <div className="titulo">Qual o local de instalação?</div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div>
          <TextField
            id="Morada"
            name="morada"
            label="Morada *"
            className="morada"
            value={localidade.morada}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8 }}
            {...(errorsLocalizacao.morada && {
              error: true,
              helperText: errorsLocalizacao.morada,
            })}
          />
          <TextField
            id="cui"
            name="cui"
            label="CUI */**"
            className="cui"
            value={localidade.cui}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            style={{ marginBottom: 8 }}
            inputProps={{ maxLength: maxLength }}
            {...(errorsLocalizacao.cui && {
              error: true,
              helperText: errorsLocalizacao.cui,
            })}
          />
        </div>
        <div>
          <TextField
            id="localidade"
            name="localidade"
            label="Localidade *"
            value={localidade.localidade}
            className="localidade"
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            {...(errorsLocalizacao.localidade && {
              error: true,
              helperText: errorsLocalizacao.localidade,
            })}
          />
          <TextField
            id="cod_postal"
            name="cod_postal"
            label="Código Postal *"
            className="cod_postal"
            value={localidade.cod_postal}
            onChange={handleChange}
            fullWidth
            autoComplete="off"
            inputProps={{ maxLength: 8 }}
            {...(errorsLocalizacao.cod_postal && {
              error: true,
              helperText: errorsLocalizacao.cod_postal,
            })}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography variant="p" style={{ fontSize: 10 }}>
            ** Pode consultar o CUI na sua fatura, junto dos dados do cliente,
            ou com a distribuidora de gás da sua região
          </Typography>
        </div>
        {/* Nova Ligação */}
        {/* <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.nova_ligacao}
                  onChange={handleChangeNovaLigacao}
                  name="nova_ligacao"
                />
              }
              label="Esta é uma nova ligação"
            />
          </div>
        </FormGroup>
        {/* Fornecimento de energia */}
        {/* <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={localidade.fornecimento_energia}
                  onChange={handleChangeFornecimentoEnergia}
                  name="fornecimento_energia"
                />
              }
              label="Este local tem fornecimento de energia"
            />
          </div>
        </FormGroup> */}
        <div>
          <FormControl style={{ marginTop: 20 }}>
            <FormLabel id="fornecimento_gas">
              O local tem atualmente fornecimento de gás?
            </FormLabel>
            <RadioGroup row name="position">
              <FormControlLabel
                value="yes"
                control={
                  <RadioColored
                    checked={localidade.fornecimento_gas === "yes"}
                    onChange={handleChangeRadioFornecimento}
                    name="fornecimento_gas"
                  />
                }
                label="Sim"
              />
              <FormControlLabel
                value="no"
                control={
                  <RadioColored
                    checked={localidade.fornecimento_gas === "no"}
                    onChange={handleChangeRadioFornecimento}
                    name="fornecimento_gas"
                  />
                }
                label="Não"
              />
            </RadioGroup>
          </FormControl>
          <div>
            {errorsLocalizacao.fornecimento_gas && (
              <FormHelperText style={{ color: "#f44336" }}>
                {errorsLocalizacao.fornecimento_gas}
              </FormHelperText>
            )}
          </div>
        </div>
        {localidade.fornecimento_gas === "no" && (
          <div>
            <Typography>
              Relembramos que será necessário:
              <ul style={{ marginLeft: 20 }}>
                <li>
                  enviar-nos um comprovatico em como pode contratar energia
                  nesta morada;
                </li>
                <li>
                  efectuar uma inspeção à sua instalação de gás, que terá um
                  custo de 60€ (IVA incluído)
                </li>
              </ul>
            </Typography>
          </div>
        )}
        <div>
          <FormControl style={{ marginTop: 20 }}>
            <FormLabel id="inspecao_gas">
              Este local tem inspeção de gás?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
            >
              <FormControlLabel
                value="yes"
                control={
                  <RadioColored
                    checked={localidade.inspecao_gas === "yes"}
                    onChange={handleChangeRadioInspecao}
                    name="inspecao_gas"
                  />
                }
                label="Sim"
              />
              <FormControlLabel
                value="no"
                control={
                  <RadioColored
                    checked={localidade.inspecao_gas === "no"}
                    onChange={handleChangeRadioInspecao}
                    name="inspecao_gas"
                  />
                }
                label="Não"
              />
            </RadioGroup>
          </FormControl>
          <div>
            {errorsLocalizacao.inspecao_gas && (
              <FormHelperText style={{ color: "#f44336" }}>
                {errorsLocalizacao.inspecao_gas}
              </FormHelperText>
            )}
          </div>
        </div>
        {localidade.inspecao_gas === "no" && (
          <div>
            <Typography>
              Relembramos que será necessário:
              <ul style={{ marginLeft: 20 }}>
                <li>
                  enviar-nos um comprovatico em como pode contratar energia
                  nesta morada;
                </li>
                <li>
                  efectuar uma inspeção à sua instalação de gás, que terá um
                  custo de 60€ (IVA incluído)
                </li>
              </ul>
            </Typography>
          </div>
        )}
      </ThemeProvider>
    </>
  );
};

function getStepContent(
  step,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao
) {
  switch (step) {
    case 0:
      return (
        <PersonalForm
          localidade={localidade}
          setLocalidade={setLocalidade}
          validationLocalizacao={validationLocalizacao}
          errorsLocalizacao={errorsLocalizacao}
        />
      );

    default:
      return "unknown step";
  }
}

const Localizacao = ({
  value,
  methods,
  setValue,
  localidade,
  setLocalidade,
  validationLocalizacao,
  errorsLocalizacao,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const handleNext = (data) => {
    if (validationLocalizacao()) {
      setActiveStep(activeStep + 1);
    } else return null;
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setValue(value - 1);
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      {activeStep === steps.length ? (
        setValue(value + 1)
      ) : (
        <>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <Button
              className={classes.button}
              onClick={handleBack}
              style={{
                paddingRight: 10,
                borderRadius: "25px",
              }}
            >
              <ChevronLeft />
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                  marginTop: "1px",
                }}
              >
                voltar
              </span>
            </Button>
            <br />
            <br />
            {getStepContent(
              activeStep,
              localidade,
              setLocalidade,
              validationLocalizacao,
              errorsLocalizacao
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                paddingRight: 10,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Seguinte
              <span
                style={{
                  paddingRight: 0,
                  paddingLeft: 2,
                  display: "flex",
                }}
              >
                <ChevronRight />
              </span>
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default Localizacao;
