import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CheckboxColored = withStyles({
  root: {
    color: "#05b6b7",
    "&$checked": {
      color: "#05b6b7",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PreferenciaContacto = ({
  dados,
  setDados,
  errorsDados,
  validateOnChange = true,
  validationDados,
}) => {
  const handleChangePreferenciaContacto = (e) => {
    setDados({
      ...dados,
      preferencia_contacto: e.target.name,
    });
    // console.log('preferencia de contacto');
    if (validateOnChange)
      validationDados({ preferencia_contacto: e.target.name });
  };

  return (
    <>
      <div>
        <p style={{ color: "#05b6b7", marginTop: "20px" }}>
          Como prefere ser contactado?
        </p>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {/* Email */}

        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.preferencia_contacto === "email"}
                  onChange={(e) => handleChangePreferenciaContacto(e)}
                  name="email"
                />
              }
              label="Email"
            />
          </div>
        </FormGroup>

        {/* Correio Postal */}

        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.preferencia_contacto === "contacto_telefonico"}
                  onChange={(e) => handleChangePreferenciaContacto(e)}
                  name="contacto_telefonico"
                />
              }
              label="Contacto Telefónico"
            />
          </div>
        </FormGroup>

        {/* Correio Postal */}

        <FormGroup
          row
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div>
            <FormControlLabel
              control={
                <CheckboxColored
                  checked={dados.preferencia_contacto === "correio_postal"}
                  onChange={(e) => handleChangePreferenciaContacto(e)}
                  name="correio_postal"
                />
              }
              label="Correio Postal"
            />
          </div>
        </FormGroup>
      </div>
      <div>
        {errorsDados.preferencia_contacto && (
          <FormHelperText style={{ color: "#f44336" }}>
            {errorsDados.preferencia_contacto}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

export default PreferenciaContacto;
